
@import 'vars';


/*============ splash ============*/
/*  banner */
.t-home{
    .main-wrapper{
        background: url('../assets/main_bg.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        main{
         
            display: flex;
            align-items: center;
            z-index: 1500;
            a.logo{
                width: clamp(100px, 25vw, 242px);
                position: absolute;
                top: 1.5rem;
                left:1.5rem;
                z-index: 1;
                &:hover{
                    transform: scale(1.1);
                }
                @media screen and (min-width:769px) and (max-height:600px){
                    width: 25vh;
                }
            }
          
            .content-wrapper{
                max-height: 100vh;
                background: url('../assets/inner_bg.png');
                background-size: 80%;
                background-position: center;
                background-repeat: no-repeat;
                width: 100%;
                margin:auto;
                padding-top: min(56.25%, 100vh);
                object-fit: contain;
                position:relative;
                @media screen and (max-width:768px) {
                    padding-top: 0;
                }
                .area-icon{
                    position: absolute;
                    transition: transform .075s linear;
                    display: flex;
                    justify-content: center;
                    img{
                        width: 100%;
                    }
                    .title{
                        display: none;
                        background-color: #0E2547;
                        color: #fff;
                        border-radius: 25px;
                        width: auto;
                        text-align: center;
                        padding:5px 20px;
                        line-height: 1;
                        z-index: 2000;
                        position: absolute;
                        top:-20%;
                        font-size: clamp(14px, 2vw, 30px);
                        font-weight: 700;
                        padding-top:.4em;
            
                        white-space:nowrap;
                        &::after{
                            content:"";
                            background: url('../assets/arrow.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            margin-top:-2px;
                            width: 34px;
                            height: clamp(20px, 2vw, 50px);
                            object-fit: contain;
                            margin-left:5px;
                            display: inline-block;
                            transition: max-width .3s ease-in-out;
                            vertical-align: middle;
                        }
                    }
                    &:hover{
                        cursor: pointer;
                        z-index: 1;
                        img{
                            animation:  swivel .75s;
                            animation-iteration-count: infinite;
                            animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
                        }
                        .title{
                            display: inline-block;
                            transform: scale(1.1);
                        }
                    }
                }
            }

            .area-wrapper{
                position: fixed;
                height: 100%;
                left:72px;
                right:0;
                z-index: 1;
                display: none;
                opacity: 0;
                pointer-events: none;
                transition: all .5s ease-in-out;
                @media screen and (max-width:768px) {
                    left:0;
                }
                .relative{
                    max-height: 100vh;
                    position: relative;
                    height: 100%;
                }
               
                .background{
                    display: block;
                    position: fixed;
                    width:100%;
                    height: 100%;
                    pointer-events: none;
                    background-color: #848484;
                    img{
                   
                    
                        height: 100%!important;
                        width: 100%;
                        object-fit: cover;
                        mix-blend-mode: multiply;
                        z-index: -1;
                    }
                
                }
                .logo{
                    position: fixed;
                    bottom:1.5rem;
                    right: 10vw;
                    z-index: 2100;
                    max-width: 242px;
                    width:25vw;
                    min-width: 150px;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.1);
                    }
                    @media screen and (max-width:768px) {
                        display:none;
                    }
                }
                &.active{
                    pointer-events:all;
                    display: block;
                }
                &.show{
                    opacity: 1;
                }
                .contentwrap{
            
                    position: relative;
                    z-index: 2000;
                    min-height: 100%;
                    padding-top: 2rem;
                    // padding-left: 4rem;
                    @media screen and (max-width:768px) {
                        padding-top: 4rem;
                        padding-left: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    .area-icon {
                        background-color: get_color('yellow');
                        border-radius: 50%;
        
                        aspect-ratio: 1 / 1;
                        min-width: 200px;
                        max-width: 337px;
                        width: 25vw;
                        position: relative;
                        transform: scale(1.1);
                        picture{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            img{
                                width: 100%;
                                margin:auto;
                                height: 100%!important;
                                position: absolute;
                                top: 0;
                                left: 0;
                                object-fit: contain;
                            }
                        }
                        
                    }
                    .area-content{
                        max-width: 410px;
                        color:#fff;
                        font-size: clamp(16px, 2.5vw, 22px);
                        .title{
                            white-space: nowrap;
                            font-size: clamp(16px, 4vw, 50px);
                            font-weight: 700;
                            
                        }
                        .button{
                            margin-left:0;
                            line-height: 52px;
                            
                        }
                    }
                
                
                }
            }
            & > .page-title-wrapper{
                position: absolute;
                top:0px;
                .page-title{
                    display: none;
                }
            }
            @media screen and (max-width:768px) {
                // overflow-y: scroll;
                flex-direction: column;
                // &::-webkit-scrollbar
                // {
                //     width: 12px;
                //     background-color: #0E2547;
                // }
                // &::-webkit-scrollbar-thumb
                // {

                //     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                //     background-color: #fff;
                // }
                & > .page-title-wrapper{
                    position: relative;
                    top:unset;
                    padding-bottom: 2rem;
                    a.logo{
                        position: relative;
                        width: max(150px, 40vw) !important;
                        height: max(150px, 40vw) !important;
                        aspect-ratio: 1/1;
                        max-width:unset;
                        top:unset;
                        left:unset;
                        display: flex;
                       
                        img{
                           
                            margin:auto;
                        }
                    }
                }
               
                .area-wrapper {
                    & > .contentwrap{
            
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                       
                    }
                   .close-btn{
                        top:2rem;
                        left:20px;
                        right: unset;
                   }
                } 
                
                .content-wrapper{
                    background: none;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                
                    flex-grow: 1;
                    .area-icon{
                        width: 100%!important;
                        align-items: center;
                        flex-direction: column;
                        position: relative;
                        top: unset !important;
                        left:unset !important;
                
                        margin-bottom: 5rem;
                        .title{
                            display: inline-block;
                            top:0;
                            z-index: 1;
                            margin-top: 1rem;
                            position: static;
                            font-size: clamp(18px, 5vw, 22px);
                            padding: 1rem 2.5rem;
                            border-radius: 40px;
                            &::after{
                                height: 30px;
                                margin-right: -25px;
                            }
                        }
                        picture{
                            width: max(150px, 40vw) !important;
                            height: max(150px, 40vw) !important;
                           
                            aspect-ratio: 1/1;
                            background-color: get-color('yellow');
                            border-radius: 50%;
                            display: flex;
                            align-items: center; 
                            justify-content: center;
                            overflow: visible;
                            img{
                                width: 80vw;
                                max-width: 300px;
                                max-height: 100%;
                            }
                        }
                    
                
                        
                    }
                }
            }
        }
        
    }
}

@keyframes swivel {
    0% {transform: scale(1.15) rotate(2deg)}
    50% {transform: scale(1.15) rotate(2deg)}
    51% {transform: scale(1.16) rotate(-3deg)}
    100% {transform: scale(1.16) rotate(-3deg)}
}
