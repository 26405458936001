@import 'vars';
/*============ Contact ============*/

.map-wrap {
    width: 100%;
}

.contact-details p {
    margin: 5px 0;
}
.opening-hours {
    display: block;
}
.contact-wrapper form {
    padding-top: 20px;
}
.contact-wrapper form p {
    margin: 10px 0;
}

.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 280px;
    margin-bottom: 2rem;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}
.upload-icon{
    content: url("../assets/upload.svg");
}
.upload-wrapper{
    .button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .upload-icon{
        margin:auto 1rem;
    }
}
.file-upload-tool{
    display: none;
}
.file-upload-container{
    a.file-browse{
        display: none;
    }
    a{
        margin-top: 1rem;
    }
    font-size: 16px;
    
}
.t-contact {
    
    .owna-btn-wrapper{
        width: 100%;
        a.button{
            margin-left:0;
            margin-top: 5px;
            width: auto;
            color:#fff;
            padding:.75rem 2rem;
            // white-space: nowrap;
            font-size: clamp(16px, 2vw, 20px);
            min-height: 52px;
            height: auto;
            display: flex;
            align-items: center;
            line-height: 1.4;
            &:hover{
                transform: scale(1.15);
            }
        }
    }
    .form-result-wrapper{
        text-align: center;
        color: #fff;
        margin-top: 4rem;
        h2{
            font-weight: 700;
        }
    }
    .content-wrapper{
        padding-top: 0;
    }
    .recaptcha-notice{
        color:#fff;
        text-align: right;
        margin-top: 1rem;
        a{
            color: #fff;
            text-decoration: underline;
        }
    }
    .menu-contact-wrapper{
        padding:2rem 1rem;
        padding-top: 0;
    }
    .form-wrapper{
        .two-col{
            grid-gap:.5rem 1rem;
            margin-bottom: .5rem;
        }
    }
    // .content-wrapper{
    //     padding-top: 18rem;
    // }
    @media screen and (max-width:1200px) {
        .menu-contact-wrapper{
            & > .two-col{
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
    @media screen and (max-width:768px) {
        .menu-contact-wrapper{
            & > .two-col{
                grid-template-columns: 1fr;
            }
        }
        .contact-details{
            width: fit-content;
            justify-content: center;
            margin:0 auto;
        }
        .social-details{
            justify-content: center;
            flex-direction: column;
            h4{
                margin-right: 0;
            }
        }
        form{
            .button{
                width: 100%;
            }
        }
    }
}