@import 'vars';

/*============ header ============*/
header.top {
    font-family: $font-primary;
    font-size:14px;
   
    background-color:#ffffff;
   
    min-width: 72px;
    z-index: 1000;
    .header-wrapper{
        position: fixed;
        height: 100%;
        width: 72px;
        background-color:#ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
