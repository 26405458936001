/** site links **/
.site-link {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 5px;
    img{
        height: 100%!important;
        width: 100%!important;
        object-fit: contain;
    }
    &:hover{
        animation-name: bounce;
        animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
}
@keyframes bounce {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-20px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-2px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}