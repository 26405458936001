/*============ footer ============*/
footer .footer-bottom {
    background-color:#000000;
    /*background:#000000 url('/app/img/footer-bg.jpg') center no-repeat;*/
    background-size:cover;
    color:#fff;
}
.site-map > ul {
    display:table;
    width:100%;
}
.site-map > ul > li {
    display:table-cell;
    padding:20px 0;
}
.site-map li li {
    display:block;
    margin:10px 0;
}
.site-map a {
    color:inherit;
    text-decoration:none;
    display:block;
    padding-left:30px;
    position:relative;
    line-height:30px;
}
/** bykeo ***/
.bykeo {
    margin-top: auto;
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: #fff;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    transition: transform 500ms ease;
}
.bykeo a:hover{
    color:#fff;
    font-size: 1.2em;
    img{
        
        transform-origin: center;
        transform: translateX(-200%) rotate(-360deg);
    }
} 