
@import 'vars';

/*============ sub page============*/
.close-btn{
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid white;
    border-radius: 50%;
    position: fixed;
    right: 25px;
    top: 25px;
    height: 40px;
    width: 40px;
    color:black;
    line-height: 40px;
    text-align: center;
    font-weight: 900;
    font-family: serif;
    font-size: 18px;
    cursor: pointer;
    pointer-events: all;
    z-index: 2100;
    background-color: #fff;
    &:hover{
        transform: scale(1.2);
    }
}
.main-wrapper{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: stretch;

    main{
        flex-grow: 1;
        position: relative;
        height: fit-content;
        min-height: 100vh;
        .page-title-wrapper{
            display: grid;
            grid-template-columns: 1fr minmax(200px, 1200px) 1fr;
            padding:2rem;
            padding-bottom: 0;
            align-items: center;
            width: 100%;
            a.logo{
        
                position: static;
                top: 1.5rem;
                left: 1.5rem;
            }
            .page-title{
                padding-left: 20px;
                font-weight: 700;
                color: #fff;
                font-size: clamp(24px, 5vw, 50px);
            }
            @media screen and (max-width:768px) {
                height: 20rem;
                top: 20px;
                grid-template-columns: 1fr;
                justify-content: center;
                justify-items: center;
                .page-title{
                    padding-left: 0;
               
                }
                a.logo{
                    max-width: 100%;
                }
            }
        }
    }

    @media screen and (max-width:768px) {
        flex-direction: column;
        header.top{
            position: fixed;
            top: 3rem;
            right: 10px;
            z-index: 10000;
            background-color: transparent;
            .header-wrapper{
                position: relative;
                background-color: transparent;
                overflow: hidden;
            }
            #mobile-menu{
                overflow: visible;
                & > span {
                    background-color: #fff;
                }
            }
        }
  
        .slide-menu{
            left:0;
        }
    }
}


.t-page, .t-staff, .t-contact{
    font-size: clamp(16px, 3vw, 24px);
    .page-title{
        font-size: clamp(24px, 5vw, 50px);
        font-weight: 700;
        color:#fff;
    }
    .content-wrapper{
        padding-top: 2rem;
        padding-bottom: 6rem;
        // min-height: 100vh;
        // @media screen and (max-width:768px) {
        //     padding-top: 19rem; 
        // }
    }
    
}

.t-staff{
    
    &.staff-item{
        .page-title{
            display: none;
        }
    }
   
    .content-wrapper{
        padding-top: 0;
    }   
    .staff-card{
        display: flex;
        flex-direction: column;
        padding: 2rem;
        text-align: center;
        color:#fff;
        h4{
            padding:0;
            margin: 0;
            margin-bottom: 10px;
            font-size: clamp(18px, 4vw, 24px);
        }
        .icon{
            margin-bottom: 2rem;
            padding-bottom: 100%;
            width: 100%;
            position: relative;
           
            img{
                width: 100%;
                height: 100%!important;
                // aspect-ratio: 1/1;
                object-fit: contain;
                transform: scale(1.1);
                position: absolute;
                top:0;
                left:0;
                border-radius: 50%;
                border: 6px solid #fff;
                // max-width: 300px;
            }
            margin-bottom: 2rem;
        }
        &:hover{
            img{
                animation:  swivel .75s;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
            }
            h4{
                transform: scale(1.2);
            }
        }
    
        @media screen and (max-width:300px) {
            padding: 0;
        }
    }
    .staff-details-wrapper{
        display: flex;
        .staff-card{
            width: 100%;
            max-width: 500px;
            margin-right: 2rem;
        }
        .staff-content{
            max-width: 575px;
        }
        @media screen and (max-width:992px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            .staff-card{
                margin-right: 0;
            }
        }
    }
    &.t-projects{
        .staff-card .icon {
            border-radius: 50%;
            border: 6px solid #fff;
            img{
                object-fit: contain;
                border-radius: 0%;
                border:none;
            }
        }
        }
    @media screen and (max-width:768px) {
        .close-btn{
            top:2rem;
            left:20px;
        }
    }
}

@keyframes swivel {
    0% {transform: scale(1.15) rotate(2deg)}
    50% {transform: scale(1.15) rotate(2deg)}
    51% {transform: scale(1.16) rotate(-3deg)}
    100% {transform: scale(1.16) rotate(-3deg)}
}
.menu-contact-wrapper{
    font-size: 18px;
    color: #fff;
    
    .contact-details{
        .item{
            margin:0;
            display: flex;
            .label{
                display: inline-block;
                width: auto;
                min-width:min(14vw, 140px);
            }
        }
    }
    .social-details{
        align-items: flex-start;
        h4{
            font-weight: 400;
            margin-right: 2rem;
            margin-top: 0;
            margin-bottom: 0;
            // line-height: 35px;
        }
        .site-links{
            margin-top:-5px
        }
    }
    @media screen and (max-width:1200px) {
        & > .two-col{
            grid-template-columns: 1fr;
        }
    
    }
    @media screen and (max-width:768px) {
        .social-details{
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .row{
                flex-direction: column;
                align-items: center;
            }
            h4{
                margin-right: 0;
                margin-bottom: 1rem;
            }
            .site-links{
                margin-bottom: 1rem;
            }
        }
    }
    @media screen and (max-width:576px) {
        .contact-details{
            text-align: center;
            .item{
                justify-content: center;
                padding-bottom: 5px;
                .label{
                    display: none;
                }
            }
           
        }
     
    }
    @media screen and (max-width:300px) {
        .contact-details{
            display: none;
        }
    }
}
.gallery_wrapper{
    position: fixed;
    height: 100%;
    left: 72px;
    right:0;
    z-index: 10;
    display: none;
    opacity: 0;
    pointer-events: none;
    transition: all .5s ease-in-out;
    .kmsgallery{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        .kmsgallery-thumbnail-image{
            width: 100%;
            height: auto;
            margin:0;
            aspect-ratio: 16/9;
            background-repeat: no-repeat;
            &:nth-child(3n){
                grid-column: 1/-1;
            }
        }
    }
    &.active{
        pointer-events:all;
        display: block;
    }
    &.show{
        opacity: 1;
    }
    
    @media screen and (max-width:768px) {
        left:0;
        .close-btn{
            top:2rem;
            left:20px;
            right: unset;
        }
    }
}
.mfp-bg, .mfp-wrap{
    z-index: 10000;
}

.pager{
    text-align: center;
    .button{
        border:none;
        margin:0 .75rem;
        display: inline-block;
        width: auto;
        &:hover{
            transform: scale(1.4);
        }
    }
}
.alerts-active{
    .close-btn, #mobile-menu{
        position: absolute;
    }
}