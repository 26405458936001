
@import 'vars';

#mobile-menu {
    display: block;
    width: 27px;
    height: 30px;
    margin: 2px;
    margin-bottom: 0;
    cursor: pointer;
    z-index:100;
    position: fixed;
}
#mobile-menu > span {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background-color: get_color('green');
    margin: 6px 0;
    transition: all 0.5s ease;
}
.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}
.slide-menu-active #mobile-menu span.three {
     -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
    transform: rotate(45deg) translate(-6px, -6px) ;
}
.slide-menu-active #mobile-menu span.two {
    opacity: 0;
}

/***** Slide menu ****/

.slide-menu {
	background: get_color('green');
    z-index:5000;
	position: absolute;
	height: 100%;
    inset: 0;
	right: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    
    padding:1rem 0;
    left:72px;
    max-width: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .contentwrap{
        width: 100%;
    }
    &::-webkit-scrollbar
    {
        width: 12px;
        background-color: #0E2547;
    }
    &::-webkit-scrollbar-thumb
    {

        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #fff;
    }
    .menu-title{
        display:grid;
        grid-template-columns: 1fr 1200px 1fr;
        width: 100%;
        grid-column: 1 / -1;
        justify-content: flex-start;
        h2{
            font-size: clamp(16px, 3vw, 50px);
            font-weight: 700;
            display: flex;
            align-items: center;
            padding:0 20px;
        }
        a.logo{
            max-width: 242px;
            width:25vw;
            margin-left:2rem;
        }
    }
}
.slide-menu-active .slide-menu {
    // left:72px;
    right:0%;
    max-width: 100%;

}
.site-content-menu-slide {
    overflow:hidden;
    position:relative;

}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
	-webkit-transition: all 0.5s ease-out;
	-moz-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}
.slide-menu .menu-title {
	color:#fff;
	margin:0;
	font-weight:bold;
    @media screen and (max-width:576px) {
        grid-template-columns:1fr;
        justify-items: center;
        h2{
            display: none;
        }
        a.logo{
            margin-left: 0;
            min-width: 160px;
        }
    }
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
    a {
        display: flex;
        color: #fff;
        text-decoration:none;
        align-items: center;
        &::before{
            content:"";
            background: url('../assets/arrow.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-top:.5rem;
            width: 34px;
            height: clamp(20px, 2vw, 50px);
            object-fit: contain;
            max-width: 0px;
            // overflow: hidden;
            display: inline-block;
            transition: max-width .3s ease-in-out;
            vertical-align: middle;
        }
        .title{
            font-size: clamp(16px, 3vw, 50px);
            font-weight: 700;
            min-height: 50px;
            display: inline-flex;
            align-items: center;
            padding-top:1rem;
            white-space: nowrap;
            @media screen and (max-width:576px) {
                min-height: unset;
            }
        }
    }
}
.slide-menu ul ul {
	background:#999;
}
.slide-menu ul ul ul{
	background:#666;
}
.slide-menu li {
    position:relative;
    display: flex;
    align-items: center;
}
.slide-menu li > ul {
    display:none;
}
.slide-menu li.expand-active > ul {
    display:block;
}

.slide-menu li.has-sub {
    position: relative;
    display: block;
}
.slide-menu li.has-sub > a {
    position: relative;
    border-right: 1px solid #030b16;
    margin-right: 50px; /* space for drop */
}
.slide-menu a:hover {
	color: #0E2547;
    &::before{
       max-width: 40px;
    }
}
.slide-menu li.has-sub > a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: #030b16;
    width: 50px;
    height: 1px; /* border bottom */
    z-index: 100;
}
.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #030b16;
    height: 54px;
    width: 50px;
    cursor: pointer;
}
.slide-menu ul .expand i {
    content:"";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.menu-wrapper{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    @media only screen and (max-width: 576px) {
        grid-template-columns: 1fr;
        ul li{
            justify-content: center; 
        }
    }
}
.expand-active > .expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
@media only screen and (max-width: 960px) {
    // #mobile-menu {
    //     display:block;
    // }
    // .header-menu,
    // .main-menu {
    //     display:none;
    // }
    // .slide-menu {
    //     display:block;
    // }
    // .slide-menu-active .site-content-menu-slide {
    //     right: 240px;
    // }
    // .slide-menu-active #mobile-menu {
    //     right: 260px;
    // }


}