@import 'vars';

/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
}
form p {
    position: relative;
    margin: 5px 0;
}
input {
    height: 52px;
    line-height: 52px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding:0;
    padding-left: 20px;
    font-family: $font-primary;
    font-size: 20px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 26px;
    border:none;
}
textarea {
    min-height: 80px;
    padding:15px;
    padding-left: 20px;
}
label.float {
    color: #fff;
    padding-left: 20px;
    max-width: 100%;
    font-size: 18px;
    line-height: 27px;
}

button.button {
    margin-left: auto;
    margin-top: 2rem;
}